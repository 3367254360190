<template>
<div>
  <Header />
<div v-if="!showForm" class="mx-auto mb-5 col-md-10 col-12 banner overflow-hidden p-0">
     <div class="index-2 mt-5 mx-auto col-sm-12	col-md-12 col-xs-12	col-lg-8 col-xl-8 banner overflow-hidden">
            <div class="row mt-3">
                <div class="col-sm-8 col-8 col-md-4 col-lg-4 col-xl-4 mx-auto">
                     <img src="@/assets/made-to-size.svg" />
                </div>
            </div>
        </div>
     <div class="row mt-5 mb-3 mx-auto">
        <div class="col-12 col-md-6 pl-0 mb-4">
            <span class="red-title red-text"><img src="@/assets/estrella.svg" class="title-star section-icon workshop-title-icon mr-1">I'm interested in:</span><br>
            <span class="learning-goal-title ml-5">Please select up to 5 Learning Goals</span><br>
            <span class="learning-goal-text ml-5">Workshop available in English and Spanish</span>
        </div>
        <div class="col-11 m-auto col-md-6 px-0">
            <div class="row search-box mt-2 mr-md-2">
                <div class="col-12 px-0">
                    <v-autocomplete :items="searchItems" :auto-select-one-item="false" v-model="item" :component-item='template' :min-len=2 @item-selected="selectFromInput" @update-items="findGoals">
                    </v-autocomplete>
                 </div>
            </div>
        </div>
        
            
        
    </div>

  

    <div class="row mx-auto">

        <div :key="index" 
            v-for="(boomModule,index) in learningGoals" 
            class=" col-md-2 col-4 d-flex justify-content-center px-md-0 w-md-100" 
            v-bind:class="getColumnClass(index+1)"> 
            <div  class="d-block">
                 <div 
                    :key="key" v-for="(learningGoal,key) in boomModule"  
                    class="row my-2 mx-0 text-center learning-goal"
                    :class="isSelected(learningGoal)? ' selected-goal': ''" 
                 >
                   <div class="col-12"> 
                         <div class="row h-25 d-flex justify-content-end px-1">
                            
                              <span
                                v-if="isSelected(learningGoal)"
                                
                                 
                              > <img
                              :data-value="learningGoal"
                                v-on:click="removeLg(learningGoal.learningGoal)"
                                src="@/assets/tache.svg" class="remove-x clickable"> </span>
                            
                         </div>
                         <div class="row h-50 d-flex justify-content-center ">
                             <div class="col-12 d-flex justify-content-center clickable">
                             <span class="align-self-center lgoal-text"
                                v-on:click="selectLg(learningGoal)"
                                :data-value="learningGoal"  
                                data-toggle="modal" 
                                data-target="#exampleModal"

                             >
                                {{learningGoal.learningGoal}}
                             </span>
                             </div>
                         </div>
                   </div> 
                 </div>
            </div>
        </div>
    </div>
     <div>

  </div>



</div>

<div v-if="showForm" class="mx-auto mb-5 col-md-8 col-12 banner overflow-hidden p-0 ">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 d-flex">
                        <img src="@/assets/estrella.svg"  class="workshop-title-icon" />  <span class="red-title red-text"> Made to Size Workshop</span> <br>
                        </div>
                    </div>
                    <span class="learning-goal-title">We will contact you to agree on details, dates and times convenient for you</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                     <span class="d-block blue-title">Learning goals:</span>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                   
                    
                    <span v-for="(lGoal, index) in selectedGoals" :key="index" class="d-block learning-goal-title">
                        <img  src="@/assets/acierto.svg" width="20">
                        {{lGoal.learningGoal}}
                    </span>
                    
                </div>
                <div class="col-4 d-inline ">
                    <span class="clickable reset-link video-text" v-on:click="showForm=false"> Change your selection </span>
                </div>
                <div class="col-4 d-flex justify-content-end">
                    <img class="form-logo"  src="@/assets/made-to-size.svg" />
                </div>
            </div>
            <div  class="row mt-2">
                
               <!-- form -->
                <div class="col-12">
                 <div v-if="formSuccess" class="row">
                        <div class="col-10 col-md-4 mx-auto ">
                            <div  class=" contact-us-button text-center mt-50">
                                Format sent!
                            </div>
                        </div>
                </div>
                 <b-form v-if="!formSuccess">
               


                <div class="form-group">
                    <div class="row blue-form mx-0">
                <label for="name" class="col-4">*Name:</label>
                    <div class="col-8">
                    <input v-model="form.name" type="text" class="form-control" name="name" id="name">
                    </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form mx-0">
                <label for="email" class="col-4">*Email:</label>
                    <div class="col-8">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email">
                    </div>
                    </div>
                </div>

                  <div class="form-group">
                    <div class="row blue-form mx-0">
                        <label for="mobile" label="*Mobile:" class="col-4">*Mobile:</label>
                            <div class="col-4 mr-0"> 
                                <select v-model="form.countryCode" class="menu-select py-2 pr-5">
                                    <option  v-for="(code, key)  in this.phoneCodes" :key="key" :value="code.dial_code">{{code.code+code.dial_code}}</option>
                                </select>
                            </div>
                            <div class="col-4 ml-0 pl-0">
                                <input v-model="form.mobile" type="phone" class="mx-0 form-control blue-form" name="mobile" id="mobile">
                            </div>
                    </div>
                </div>



                <div class="form-group">
                    <div class="row blue-form mx-0">
                        <div  class="col-4 pr-0 form-label mr-1">*Preferred language:</div>
                        <div class="col-7 pl-0 ml-4"> 
                            <select v-model="form.language" class="ltr-select px-5 py-3">
                                <option value="English">English</option>
                                <option value="Spanish">Spanish</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div class="form-group">
                    <div class="row blue-form mx-0">
                    <label for="comments" class="col-4">Comments:</label>
                    <div class="col-8 ">
                            <textarea v-model="form.comments"  
                            rows="4" 
                            class="form-control comments-text" 
                            name="comments" 
                            id="comments"
                            style="background-color: #b2cee4;  color: #000000;
                                    font-weight: bold; border-color:#007bff1a;
                                    width:95%; font-size:1.6rem;
                                    border-bottom: 1px solid #BB0921;;
                                    "
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                 <div class="col-7 form-check d-flex justify-content-center">
                     <div class="align-self-center">
                        <input type="checkbox" value=true v-model="form.privacyPolicy" class="red-checkbox mx-2" id="exampleCheck1">
                        <label class="outer-label mx-1" for="exampleCheck1">
                             <a style="color: #002A49 !important;" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf"> 
                            I have read and accept the Privacy Policy
                           </a>
                        </label>
                     </div>
                </div>

                 <div class="col-5 form-check  d-flex justify-content-end">
                    <button  v-on:click.prevent="send()" :disabled="isFormfilled" :class="disabledClass" class="contact-us-button" for="exampleCheck1">SUBMIT <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>

                </div>
                
                 </b-form>
                </div>
               <!--enf form -->
      
        </div>
</div>
<vue-final-modal v-model="showModal" :overlay-style="overlayStyle">
    <div class="row mt-2"> 
        <div class="col-9">
        </div>
        <div class="col-md-1 col-1">
            <div v-on:click="showModal=false" class="clickable"> 
                <img src="@/assets/tache.svg" class="remove-x">
            </div>
        </div>
    </div>
    <div class="row" >
        <div class="col-md-8 col-10  m-auto modal__content" >
            <div class="row mt-0">
                <div class="col-4 align-self-center">
                    <div class="row">
                        <div class="col-12" v-if="selectedGoals.length > 0">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center">
                                    <button class="modal-button" type="submit">
                                        <button @click="showMadeToSizeForm()" class="modal-button"  type="submit">
                                            <img  src="@/assets/acierto.svg"> 
                                        </button>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div  class="mt-1 col-12 d-flex justify-content-center">
                                    <span class="text-center learning-goal-title"> Workshop Ready? </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <img  class="m-auto super-hero avatar"  :src="madeToSize.image">
                </div>
                <div class="col-4 align-self-center h-100">
                
                    <div class="row">
                        <div class="col-12" v-if="selectedGoals.length < 5">
                            <div class="row ">
                                <div class="col-12 d-flex justify-content-center">
                                    <button @click="closeModal()" class="modal-button"  :disabled="selectedGoals.length>=5" type="submit">
                                        <img  src="@/assets/mas.svg"> 
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="mt-1 col-12 d-flex justify-content-center">
                                    <span class="text-center learning-goal-title"> Add more Learning Goals </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="goal-container">
                <div v-for="(lGoal, index) in selectedGoals" :key="index" class="row learning-list mt-1 ">
                    <div class="col-10 py-1">
                        <div class="learning-goal-title text-center">{{lGoal.learningGoal}}</div>
                    </div>
                    <div class="col-1 py-2">
                        <span class="clickable"><img
                                    
                                    :data-value="lGoal"
                                    v-on:click="removeLg(lGoal.learningGoal)"
                                    src="@/assets/tache.svg" class="remove-x"></span>
                    </div>
                </div>
            </div>
            <div class="row dark-blue-bg goal-container">
                <div class="col-5 light-title d-block justify-content-center">
                    <div class="row mt-2">
                        <div class="col-12 m-auto text-center">DURATION:</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 text-center modal-bold">{{madeToSize.duration}}</div>
                    </div>
                </div>
                <div class="col-2 d-flex justify-content-center py-3">
                    <div class="white-division"></div>
                </div>
                <div class="col-5 light-title d-block justify-content-center">
                    <div class="row mt-2 mx-auto">
                        <span class="col-12 text-center">INVESTMENT:</span>
                    </div>
                    <div class="row mb-2">
                        <span class="col-12 text-center modal-bold">${{madeToSize.investmentByPerson}} USD</span>
                    </div>
                </div>     
            </div>

            
        </div>
    </div>
     <div v-if="formSuccess" class="row">
       <div class="col-10 col-md-4 mx-auto ">
          <div  class=" contact-us-button text-center mt-50">
            Format sent!
          </div>
       </div>
     </div>    
</vue-final-modal>



  <Footer/>


</div>
</template>

<script>
// S E R V I C E S
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import Item from "@/components/Item.vue"
import EmailService from "@/service/email"
import phoneCodes from "@/data/phoneCodes"

import LeargningGoalService from "@/service/learningGoals"


import {  VueFinalModal } from 'vue-final-modal'

  export default {
     components: {
       Header,
       Footer,
       VueFinalModal,
  },
   created() {
    this.phoneCodes = phoneCodes.phoneCodes;

  },
  
    data() {
      return {
        showForm:false,
        formSuccess: false,
        template: Item,
        form:{
            name:"",
            email:"",
            countryCode:"+52",
            mobile:"",
            comments:"",
            formSuccess:false,
            language:"",
            selectedGoals:[],
            privacyPolicy:false,
            phoneCodes:[],
            itemTemplate:"",
            subject:"Made to size"
        },
        settings:{
            Id:1,
            hourPrice:0,
            zeroDiscount:0,
            firstDiscount:0,
            secondDiscount:0,
            thirdDiscount:0,
            fourthDiscount:0
        },
        columnSize:0,
        searchItems: [],
        learningGoals:[],
        showModal:false,
        overlayStyle:{
            background:"#FFFFFF",
            opacity:0.9,
            overflow:"visible"
        },
        selectedGoals:[],
        madeToSize:{
            image:"",
            duration:0,
            regularInvestment:"",
            earlyBird:"",
            investmentByPerson:"",
            attendance:""

        }
      }
    },

    mounted(){
        window.scrollTo(0,0);
        this.getLearningGoals();
        this.getSettings();
    },

      computed: {
        isSelected() {
            return element => this.selectedGoals.includes(element);
        },

         isFormfilled(){
        if(this.form.name !="" &&
            this.form.mobile !="" &&
            this.form.email !="" &&
            this.form.comments !="" &&
            this.form.language !="" &&
            this.form.privacyPolicy == true
            ){     
                return false;
            }
            return true;

         },

        disabledClass(){
            if(this.isFormfilled){
                return "disabled-button";
            }
            return "clickable";
        }
  },

    watch:{
      formSuccess: function(){
        if(this.formSuccess){
          window.setTimeout(()=>{
            this.formSuccess = false;
            this.showForm = false;
          }, 3000);
        }
      }
    },

    methods:{
        getLearningGoals(){
          LeargningGoalService.getLearningGoals().then(res => { 
            this.res  
            this.responseArray = res.data;
            this.columnSize = Math.ceil(this.responseArray.length/6);
            this.fillColumns();
            
            
        });
    },

    fillColumns(){
        this.learningGoals=[];
        while(this.responseArray.length) {
            this.learningGoals.push(this.responseArray.splice(0,this.columnSize));
        }
    },

      getSettings(){
         LeargningGoalService.getSettings().then(res => { 
              
            this.settings.Id=res.data[0].Id;
            this.settings.hourPrice=res.data[0].hourPrice;
            this.settings.zeroDiscount= res.data[0].zeroDiscount;
            this.settings.firstDiscount=res.data[0].firstDiscount;
            this.settings.secondDiscount=res.data[0].secondDiscount;
            this.settings.thirdDiscount=res.data[0].thirdDiscount;
            this.settings.fourthDiscount=res.data[0].fourthDiscount;

        });
    },

    selectFromInput(e){
        if(this.selectedGoals.length < 5){
            this.selectedGoals.push(e);
             console.log(this.selectedGoals);
          this.getQuote();
          this.showModal = true;
        }
          
    },

    findGoals(text){
        this.searchItems=[];
      this.learningGoals.forEach((boomModule) => {
            boomModule.forEach((item)=>{
                if(item.learningGoal.toLowerCase().indexOf(text.toLowerCase()) !== -1){
                    this.searchItems.push(item);
                }

            })
        }
        );
        console.log(this.searchItems);
    },

    send(){
        this.form.selectedGoals = this.selectedGoals;
          EmailService.sendEmail(this.form).then(()=>{
            window.scrollTo(0,0);   
            console.log('evio exitoso')
            this.form = {};
            this.formSuccess = true;
          }).catch(()=>{
            this.form = {};
            this.showForm = false;
          });
          this.selectedGoals = [];
      },

    showMadeToSizeForm(){
        
        this.closeModal();
         window.scrollTo(0, 0);
        this.showForm = true;
    },
        
    closeModal(){
        this.showModal = false;
    },


    getQuote(){
        var duration = 0;
        
        this.selectedGoals.forEach((lGoal)=>{
            duration = duration+parseInt(lGoal.duration);
        });
        this.madeToSize.duration=duration;
        this.madeToSize.image= require("@/assets/made-to-size/MADE_0"+this.selectedGoals.length+".svg");
        this.getAttendanceAndInvesment();


    },
    
    getAttendanceAndInvesment(){
        var firstPrice = parseInt(this.madeToSize.duration)*parseInt(this.settings.hourPrice);
        var discount = 0;
        switch(this.madeToSize.duration){
            case 2:
                discount = firstPrice * parseInt(this.settings.zeroDiscount)/100;
            break;

            case 4:
                discount = firstPrice * parseInt(this.settings.firstDiscount)/100;
                break;
            case 6:
                discount = firstPrice * parseInt(this.settings.secondDiscount)/100;
            break;

            case 8:
                discount = firstPrice * parseInt(this.settings.thirdDiscount)/100;
            break;

            case 10:
                discount = firstPrice * parseInt(this.settings.fourthDiscount)/100;
            break;
        }
        this.madeToSize.investmentByPerson = Math.round(firstPrice - discount);

    },
     getREgularInvesment(){
       if(this.madeToSize.duration <= 2){
            return 1
       } else if(this.madeToSize.duration == 3 || this.madeToSize.duration==4){
           return 3
       }else if(this.madeToSize.duration>= 5){
           return 5
       }
    },
    checkLogin(){
        if (sessionStorage.getItem("token")!= undefined && sessionStorage.getItem("token")!= "") {
          this.isLoged = true;
        }
      },

    getImage(key){
           return require('@/assets/workshops/' +this.learningGoals[key].image);

      },

    selectLg(learningGoal){
        if(this.selectedGoals.length < 5){
            this.selectedGoals.push(learningGoal);
          this.getQuote();
          this.showModal = true;
        }
          
         
      },

    removeLg(goal){
        const index = this.selectedGoals.findIndex((learningGoal)=>{
           return learningGoal.learningGoal === goal;   
        });
        if (index > -1) {
            this.selectedGoals.splice(index, 1);
        }
        this.getQuote();
      },

    getColumnClass(index){

          return "column-"+index.toString();

      }
      
    }
  }
</script>
<style scoped>
.selected-goal{
    opacity:0.7;
}
</style>


